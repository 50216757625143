// === NPM
import React from "react";
import { Stack } from "@mui/material";
// === LOCAL
import GenericButton from "@/components/generics/buttons/GenericButton";
import GenericDialog from "@/components/generics/dialogs/GenericDialog";
import SecondaryButton from "@/components/styled/SecondaryButton";
import WorkshopsAccordion from "@/components/VaccinationIahp/containers/WorkshopsAccordion";
import { UserWithoutProperties } from "@/interfaces/user";
import { useAuth } from "@/routers/useAuth";
import InformationAccordion from "../../../containers/InformationAccordion";
import { IVaccinationSiteForm, IVaccinationSiteWorkshop } from "../../interface";

interface RecapDialogProps {
    onClose: () => void;
    onValid: () => void;
    vaccinationSite: IVaccinationSiteForm;
    veterinaries: UserWithoutProperties[];
    workshops: IVaccinationSiteWorkshop[];
    farmName: string;
}

export default function RecapDialog({
    onClose,
    onValid,
    vaccinationSite,
    veterinaries,
    workshops,
    farmName,
}: Readonly<RecapDialogProps>) {
    const auth = useAuth();

    const dpe = auth.userInfo.properties.dpes.find((dpe) => dpe.id === vaccinationSite.dpeId);
    const veterinary = veterinaries.find((v) => v.id === vaccinationSite.veterinaryId);

    const departmentInseeCode = workshops.filter((ws) => vaccinationSite.workshops.includes(ws.id))[0]
        .departmentInseeCode;

    const renderContent = () => (
        <Stack spacing={2} sx={{
            width: "100%"
        }}>
            <InformationAccordion
                vaccinationSite={vaccinationSite}
                dpeName={dpe.name}
                dpePostalCode={dpe.postalCode}
                dpeCity={dpe.city}
                veterinaryFirstname={veterinary.firstname}
                veterinaryLastname={veterinary.lastname}
            />
            <WorkshopsAccordion
                workshops={workshops.filter((ws) => vaccinationSite.workshops.includes(ws.id))}
                departmentInseeCode={departmentInseeCode}
                farmId={vaccinationSite.farmId}
                farmName={farmName}
            />
        </Stack>
    );

    const displayActionButton = () => (
        <>
            <SecondaryButton onClick={onClose} variant="outlined">
                Retour au formulaire
            </SecondaryButton>

            <GenericButton onClick={() => onValid()} color="primary" label="Enregistrer le chantier" />
        </>
    );

    return (
        <GenericDialog
            maxWidth="xl"
            title="Récapitulatif du chantier"
            onClose={onClose}
            renderContent={() => renderContent()}
            renderActions={displayActionButton}
        />
    );
}
