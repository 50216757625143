// === NPM
import React from "react";
import { Stack } from "@mui/material";
// === LOCAL
import { ReactComponent as Warning } from "@/assets/icons/shared/danger-triangle.svg";
import GenericDialog from "@/components/generics/dialogs/GenericDialog";
import IconBannerText from "@/components/generics/IconBannerText";
import SecondaryButton from "@/components/styled/SecondaryButton";
import WorkshopsAccordion from "@/components/VaccinationIahp/containers/WorkshopsAccordion";
import { IVaccinationSite } from "@/interfaces/vaccination";
import { statusColor } from "@/resources/CssConstant";
import InformationAccordion from "../../../containers/InformationAccordion";

interface DetailsDialogProps {
    onClose: () => void;
    vaccinationSite: IVaccinationSite;
}

export default function DetailsDialog({ onClose, vaccinationSite }: Readonly<DetailsDialogProps>) {
    const renderWarning = () => (
        <IconBannerText
            message="Ce chantier a été généré automatiquement à partir des interventions de vaccination et audits saisis avant le 1er septembre 2024. Les données relatives aux déplacements ont été renseignées sur la base des dernières saisies. Merci de prendre connaissance de l’ensemble des informations notamment celles des déplacements et les modifier au besoin."
            color={statusColor.warningText}
            backgroundColor={statusColor.warningBackground}
            icon={<Warning />}
        />
    );

    const renderContent = () => (
        <Stack
            spacing={2}
            sx={{
                width: "100%",
            }}
        >
            {vaccinationSite.migrated && renderWarning()}
            <InformationAccordion
                vaccinationSite={vaccinationSite}
                veterinaryFirstname={vaccinationSite.veterinaryFirstname}
                veterinaryLastname={vaccinationSite.veterinaryLastname}
                dpeName={vaccinationSite.dpeName}
                dpePostalCode={vaccinationSite.dpePostalCode}
                dpeCity={vaccinationSite.dpeCity}
                billingStatus={vaccinationSite.billingStatus}
            />
            <WorkshopsAccordion
                departmentInseeCode={vaccinationSite.departmentInseeCode}
                workshops={vaccinationSite.workshops}
                farmId={vaccinationSite.farmId}
                farmName={vaccinationSite.farmName}
            />
        </Stack>
    );

    const displayActionButton = () => (
        <SecondaryButton onClick={onClose} variant="outlined">
            Fermer
        </SecondaryButton>
    );

    return (
        <GenericDialog
            maxWidth="xl"
            title={`Récapitulatif du chantier n°${vaccinationSite.id}`}
            onClose={onClose}
            renderContent={renderContent}
            renderActions={displayActionButton}
        />
    );
}
