import { LocalizationType } from "./referential";

export interface IUserInfo {
    firstname: string;
    lastname: string;
    id: string;
    type: UserType;
    situation: string;
    properties: IProperties;
    email: string;
    idToken: string;
    preferences: IPreferences;
    homePreferences: string[];
    vehicle: {
        uuid: string;
        horsePower: string;
        plateNumber: string;
    };
}

interface IPreferences {
    emailNotification: boolean;
    iahp: boolean;
    butcherySector: boolean;
    cattle: boolean;
    sheep: boolean;
}

export type IPermission = Record<Partial<string>, number>;

interface IProperties {
    // Properties for UserType FAMDAM
    agencyId?: number;
    agencyName?: string;
    // Properties for UserType VETERINARY
    dpes?: IDpeProperties[];
    // Properties for UserType PHARMACIST
    pharmacyId: string;
    pharmacyName: string;
    pharmacyEmail: string;
    // Properties for UserType DGAL / DRAAF / DDPP
    name: string;
    inseeCode: string;
    [key: string]: any;
}

export interface IDpeProperties {
    id: string;
    name: string;
    manualDelivery: boolean;
    sanitaryDpe: boolean;
    postalCode: string;
    city: string;
}

export interface IDpeShort {
    ordinalNumber: string;
    name: string;
    postalCode: string;
    city: string;
    street: string;
    complementaryAddress1: string;
    complementaryAddress2: string;
    manualDelivery: boolean;
}

export interface IDpe extends IDpeShort {
    email: string;
    calypsoEmail: string;
    receiveImportReport: boolean;
    closingDate: string;
    sanitary: boolean;
    departmentInseeCode: string;
}

export enum UserType {
    ADMIN_CALYPSO = "admin_calypso",
    FAM_DAM = "fam_dam",
    VETERINARY = "veterinary",
    PHARMACIST = "pharmacist",
    ANMV = "anmv",
    DRAAF = "draaf",
    DGAL = "dgal",
    DDPP = "ddpp",
    OVVT = "ovvt",
}

export interface IType {
    key: string;
    label: string;
    manageable: boolean;
    situations: {
        key: string;
        label: string;
        allowedNotification: boolean;
    }[];
}

export interface IAgency {
    id: number;
    name: string;
    active: boolean;
}

export interface ILocalAgency {
    uuid: string;
    authorizationNumber: string;
    city: string;
    postalCode: string;
    name: string;
    openingDate: string;
    closingDate: string;
    agencyName: string;
    agencyId: string;
    archived: boolean;
}

// Interface for create user

export interface IBaseUser {
    firstname: string;
    lastname: string;
    email: string;
    type: string;
    situation: string;
    properties?: IProperties;
}

export interface IUserShort extends IBaseUser {
    id?: string;
}

export type UserWithoutProperties = Omit<IUserShort, "properties">;

export enum TourKey {
    CONTINUOUS_TRAINING = "continuous_training",
    HOME_VETERINARY = "home_veterinary",
    HOME_FAM_DAM = "home_fam_dam",
    FAM_DAM_DELIVERY = "fam_dam_delivery",
    FAM_DAM_USERS = "fam_dam_users",
}

// ---- PHARMACY

export interface IPharmacyShort {
    id: string;
    name: string;
    email: string;
}

export interface IVeterinaryShort {
    id: string;
    firstname: string;
    lastname: string;
}

export interface IAgriMinistryGeoUnitShort {
    uuid: string;
    externalId: string;
    name: string;
    type: keyof typeof LocalizationType;
    inseeCode: string;
    spaEmail: string;
    vetTrainingDepartmentEmail: string;
}

// ---- COMPANY

export interface ICompany {
    id: string;
    name: string;
    openingDate: string;
    postalCode: string;
    city: string;
}
