import { ArrayOfTwo } from "@/interfaces/global";
import { IReferential } from "@/interfaces/referential";

export interface IVat {
    key: string;
    uuid: string;
    version: number;
    startDate?: string;
    endDate?: string;
    rate: number;
}

export interface IVatVersion extends Omit<IVat, "key"> {
    used: boolean;
}

export interface IVatCreateUpdate {
    startDate?: string;
    rate: number;
}

export interface IAmv extends IVat {}

export interface IAmvVersion extends IVatVersion {}

export interface IAmvCreateUpdate extends IVatCreateUpdate {}

export interface IChargeable {
    uuid: string;
    key: string;
    description: string;
    version: number;
    startDate?: string;
    endDate?: string;
    externalMultiplierFactor?: number;
    externalMultiplierDescription?: string;
    vatKey?: string;
    amvKey?: string;
    amvMultiplierFactor?: number;
    active: boolean;
}

export interface IChargeableVersion extends Omit<IChargeable, "description" | "key"> {
    used: boolean;
}

export interface IChargeableCreateUpdate {
    startDate?: string;
    active?: boolean;
    vatKey?: string;
    amvKey?: string;
    amvMultiplierFactor?: number;
    externalMultiplierFactor?: number;
}

export interface IChargeableRequiredFields {
    amv: boolean;
    externalMultiplier: boolean;
}

export enum BillingStatus {
    NEW = "Nouveau",
    OUTSIDE_CALYPSO = "Facturé hors Calypso",
    BILLABLE = "Facturable",
    PAYMENT_REQUESTED = "Demande de paiement",
    INSTRUCTION_ONGOING = "En cours d'instruction",
    VALIDATED = "Validé DDPP",
    REJECTED = "Rejeté",
    TO_PAY = "À payer",
    PAID = "Mis en paiement",
}

export enum BillingReferentialType {
    VAT = "TVA",
    AMV = "AMV",
    CHARGEABLES = "Prestations des chantiers",
}

export interface IConstraints {
    previousDate?: string;
    nextDate?: string;
    previousRate?: number;
    nextRate?: number;
}

export enum IMemoryStatus {
    TO_PAY = "À payer",
    PAID = "Mis en paiement",
}

export interface IMemory {
    id: number;
    title: string;
    creationDate: string;
    dpeId: string;
    dpeName: string;
    type: string; //enum VACCINATION_SITE, MONITORING_INTERVENTION, à venir dans le futur
    status: string;
    invoiceUuid: string;
}

export interface ICreateMemory {
    dpeId: string;
    title: string;
    vaccinationSiteIds: number[];
}

export interface IMemoryFilters {
    id: string;
    title: string;
    creationDate: ArrayOfTwo;
    dpe: string;
    // type: string[]; // TODO : TO UNCOMMENT LE JOUR Où ON NOUS DEMANDE LE FILTRE
    status: string[];
}

export interface IMemoryCounts {
    toPay: number; // nb mémoires avec statut TO_PAY
    paid: number; // nb mémoires avec statut PAID
}

export enum BillingStatusKeys {
    NEW = "NEW",
    BILLABLE = "BILLABLE",
    OUTSIDE_CALYPSO = "OUTSIDE_CALYPSO",
    PAYMENT_REQUESTED = "PAYMENT_REQUESTED",
    REJECTED = "REJECTED",
    INSTRUCTION_ONGOING = "INSTRUCTION_ONGOING",
    VALIDATED = "VALIDATED",
    TO_PAY = "TO_PAY",
    PAID = "PAID",
}

export interface IBillable {
    id: number;
    date: string;
    userType: string;
    dpeId: string;
    dpeName: string;
    veterinaryId: string;
    veterinaryLastname: string;
    veterinaryFirstname: string;
    farmId: string;
    doseCount: number;
    animalCount: number;
    hasAudit: boolean;
    nonCompliant: boolean;
    billingStatus: keyof typeof BillingStatus;
}

export interface IBillableFilters {
    id: string;
    date: string[];
    userType: string[];
    dpe: string;
    veterinary: string;
    farmId: string;
    billingStatus: string[];
}

export interface IBillableOutsideCalypsoFilters {
    id: string;
    date: string[];
    userType: string[];
    dpe: string;
    veterinary: string;
    farmId: string;
}

export interface IBillableCounts {
    paymentRequested: number;
    instructionOngoing: number;
    validated: number;
    inPaymentMemory: number; // PAID or TO_PAY
    rejected: number;
    takenOver: number;
}

export interface IBillableUserAdminDdppCounts {
    paymentRequested: number;
    instructionOngoing: number;
    validated: number;
    inPaymentMemory: number; // PAID or TO_PAY
}

export interface IBillableVetCounts {
    rejected: number;
    takenOver: number; // PAYMENT_REQUESTED or INSTRUCTION_ONGOING
    validated: number;
    inPaymentMemory: number; // PAID or TO_PAY
}

export interface IValidatedVaccinationSite {
    id: number;
    date: string;
    veterinaryFirstname: string;
    veterinaryLastname: string;
    userType: string;
    animalCount: number;
    hasAudit: boolean;
    hasNonConformity: boolean;
}

export const ItemTypes = {
    VACCINATION_SITE_TILE: "VACCINATION_SITE_TILE",
};

export interface IBillingContext {
    billingStatuses: IReferential[];
}

export interface IBillingStatusDtoOut {
    status: string;
    reason?: string;
}

export const billingStatusOptionsStates = {
    NEW: {
        userVet: [BillingStatusKeys.NEW],
        userAdminDDPP: [BillingStatusKeys.NEW],
        other: [BillingStatusKeys.NEW],
    },
    BILLABLE: {
        userVet: [BillingStatusKeys.BILLABLE, BillingStatusKeys.OUTSIDE_CALYPSO, BillingStatusKeys.PAYMENT_REQUESTED],
        userAdminDDPP: [
            BillingStatusKeys.BILLABLE,
            BillingStatusKeys.OUTSIDE_CALYPSO,
            BillingStatusKeys.PAYMENT_REQUESTED,
        ],
        other: [BillingStatusKeys.BILLABLE],
    },
    OUTSIDE_CALYPSO: {
        userVet: [BillingStatusKeys.OUTSIDE_CALYPSO, BillingStatusKeys.BILLABLE],
        userAdminDDPP: [BillingStatusKeys.OUTSIDE_CALYPSO, BillingStatusKeys.REJECTED],
        other: [BillingStatusKeys.OUTSIDE_CALYPSO],
    },
    PAYMENT_REQUESTED: {
        userVet: [BillingStatusKeys.PAYMENT_REQUESTED],
        userAdminDDPP: [
            BillingStatusKeys.PAYMENT_REQUESTED,
            BillingStatusKeys.OUTSIDE_CALYPSO,
            BillingStatusKeys.INSTRUCTION_ONGOING,
            BillingStatusKeys.VALIDATED,
            BillingStatusKeys.REJECTED,
        ],
        other: [BillingStatusKeys.PAYMENT_REQUESTED],
    },
    REJECTED: {
        userVet: [BillingStatusKeys.REJECTED, BillingStatusKeys.BILLABLE, BillingStatusKeys.OUTSIDE_CALYPSO],
        userAdminDDPP: [BillingStatusKeys.REJECTED],
        other: [BillingStatusKeys.REJECTED],
    },
    INSTRUCTION_ONGOING: {
        userVet: [BillingStatusKeys.INSTRUCTION_ONGOING],
        userAdminDDPP: [
            BillingStatusKeys.INSTRUCTION_ONGOING,
            BillingStatusKeys.OUTSIDE_CALYPSO,
            BillingStatusKeys.PAYMENT_REQUESTED,
            BillingStatusKeys.VALIDATED,
            BillingStatusKeys.REJECTED,
        ],
        other: [BillingStatusKeys.INSTRUCTION_ONGOING],
    },
    VALIDATED: {
        userVet: [BillingStatusKeys.VALIDATED],
        userAdminDDPP: [
            BillingStatusKeys.VALIDATED,
            BillingStatusKeys.OUTSIDE_CALYPSO,
            BillingStatusKeys.PAYMENT_REQUESTED,
            BillingStatusKeys.INSTRUCTION_ONGOING,
            BillingStatusKeys.REJECTED,
        ],
        other: [BillingStatusKeys.VALIDATED],
    },
    TO_PAY: {
        userVet: [BillingStatusKeys.TO_PAY],
        userAdminDDPP: [BillingStatusKeys.TO_PAY],
        other: [BillingStatusKeys.TO_PAY],
    },
    PAID: {
        userVet: [BillingStatusKeys.PAID],
        userAdminDDPP: [BillingStatusKeys.PAID],
        other: [BillingStatusKeys.PAID],
    },
};

export interface IStatusChange {
    authorId: string;
    authorFirstname: string;
    authorLastname: string;
    date: string;
    status: string;
    comment?: string;
}
