// === NPM
import React, { useEffect, useState } from "react";
import { Card, CardContent, Stack, Typography } from "@mui/material";
import { GridColDef, GridRenderCellParams, GridSortModel } from "@mui/x-data-grid-pro";
import { DateTime } from "luxon";
// === LOCAL
import { FilterConfigurations, GenericFilters } from "@/components/generics/filters/GenericFilters";
import GenericTable from "@/components/generics/table/GenericTable";
import useTimeout from "@/hooks/useTimeout";
import { FilterType, HttpStatus, IPagination } from "@/interfaces/global";
import { ICompany } from "@/interfaces/user";
import { CALYPSO_HEADERS, defaultPagination } from "@/resources/AppConstant";
import { createPayload } from "@/resources/utils";
import UserService from "@/services/UserService";

export interface CompanyFilters {
    id: string;
    name: string;
    openingDate: string[];
    city: string;
    postalCode: string;
}

export default function Companies() {
    const [companies, setCompanies] = useState<ICompany[]>([]);
    const [inputFilters, setInputFilters] = useState<CompanyFilters>({
        id: "",
        name: "",
        openingDate: [null, null],
        city: "",
        postalCode: "",
    });
    const [pagination, setPagination] = useState<IPagination>(defaultPagination);
    const [rowCount, setRowCount] = useState<number>(0);
    const [sortModel, setSortModel] = useState<GridSortModel>([{ field: "id", sort: "asc" }]);

    useTimeout(() => setPagination((prev) => ({ ...prev, page: 0 })), [inputFilters]);

    useEffect(() => {
        getCompanies();
    }, [pagination, sortModel]);

    const getCompanies = async () => {
        const payload = {
            page: pagination.page,
            size: pagination.pageSize,
            sorts: sortModel.map((s) => `${s.field},${s.sort}`),
            ...createPayload(inputFilters),
        };
        const res = await UserService.getCompanies(payload);
        if (res.status === HttpStatus.OK) {
            setCompanies(await res.json());
            setRowCount(+res.headers.get(CALYPSO_HEADERS.TABLE_COUNT));
        }
    };

    const handlePageSizeChange = (pageSize: number) => {
        setPagination({ pageSize, page: 0 });
    };

    const handlePageChange = (page: number) => {
        setPagination({ ...pagination, page });
    };

    const columns: GridColDef[] = [
        {
            field: "id",
            headerName: "Identifiant",
            flex: 0.5,
        },
        {
            field: "name",
            headerName: "Nom",
            flex: 1,
        },
        {
            field: "openingDate",
            headerName: "Date d'ouverture",
            flex: 0.5,
            valueGetter: (params: GridRenderCellParams) =>
                params.row.openingDate ? DateTime.fromISO(params.row.openingDate).toLocaleString() : "",
        },
        {
            field: "postalCode",
            headerName: "Code Postal",
            flex: 0.5,
        },
        {
            field: "city",
            headerName: "Ville",
            flex: 1,
        },
    ];

    const filterConfigurations: FilterConfigurations<CompanyFilters> = {
        id: { label: "Identifiant", type: FilterType.INPUT },
        name: { label: "Nom", type: FilterType.INPUT },
        openingDate: { label: "Date d'ouverture", type: FilterType.DATEPICKER },
        postalCode: { label: "Code postal", type: FilterType.INPUT },
        city: { label: "Ville", type: FilterType.INPUT },
    };

    return (
        <Stack
            spacing={2}
            sx={{
                width: "100%",
            }}
        >
            <Typography variant="h4">Liste des sociétés</Typography>
            <Card>
                <CardContent>
                    <GenericFilters
                        inputFilters={inputFilters}
                        filterConfigurations={filterConfigurations}
                        initialValues={{
                            id: "",
                            name: "",
                            openingDate: [null, null],
                            city: "",
                            postalCode: "",
                        }}
                        setInputFilters={setInputFilters}
                    />

                    <GenericTable
                        rows={companies}
                        columns={columns}
                        getRowId={(row) => row.id}
                        onPageSizeChange={handlePageSizeChange}
                        onPageChange={handlePageChange}
                        page={pagination.page}
                        pageSize={pagination.pageSize}
                        autoHeight
                        sortingMode="server"
                        paginationMode="server"
                        sortModel={sortModel}
                        rowCount={rowCount}
                        onSortModelChange={(model) => setSortModel(model)}
                        sortingOrder={["asc", "desc"]}
                        filterMode="server"
                    />
                </CardContent>
            </Card>
        </Stack>
    );
}
