// === NPM
import React, { useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { Add } from "@mui/icons-material";
import { Box, Card, CardContent, Stack, Typography } from "@mui/material";
import { GridRenderCellParams, GridSortModel, GridValueFormatterParams } from "@mui/x-data-grid-pro";
import { DateTime } from "luxon";
// === LOCAL
import DeleteAction from "@/components/generics/actions/DeleteAction";
import ViewAction from "@/components/generics/actions/ViewAction";
import GenericButton from "@/components/generics/buttons/GenericButton";
import GenericDialog from "@/components/generics/dialogs/GenericDialog";
import { FilterConfigurations, GenericFilters } from "@/components/generics/filters/GenericFilters";
import PermissionsCheck from "@/components/generics/PermissionsCheck";
import GenericTable from "@/components/generics/table/GenericTable";
import useTimeout from "@/hooks/useTimeout";
import { ActionsColumnProps, FilterType, HttpStatus, IPagination } from "@/interfaces/global";
import { IReferential } from "@/interfaces/referential";
import { VaccinationOutletContext } from "@/interfaces/vaccination";
import { CALYPSO_HEADERS, defaultPagination } from "@/resources/AppConstant";
import { ACTIONS_COLUMN_WIDTH } from "@/resources/CssConstant";
import { Preference, UserSituation } from "@/resources/PermissionConstant";
import { createPayload } from "@/resources/utils";
import { routerLinks } from "@/routers/RouterConstant";
import VaccinationService from "@/services/VaccinationService";
import DetailsDialog from "./containers/DetailsDialog";
import { IOrderDetails, ITrackingOrder, TrackingOrderFilters } from "./interface";

export default function OrderTracking() {
    const navigate = useNavigate();
    const { vaccines } = useOutletContext<VaccinationOutletContext>();
    const [orders, setOrders] = useState<ITrackingOrder[]>([]);
    const [inputFilters, setInputFilters] = useState<TrackingOrderFilters>({
        id: "",
        orderDate: [null, null],
        dpe: "",
        veterinary: "",
        status: [],
        vaccineGtinCode: [],
    });
    const [pagination, setPagination] = useState<IPagination>(defaultPagination);
    const [rowCount, setRowCount] = useState<number>(0);
    const [sortModel, setSortModel] = useState<GridSortModel>([{ field: "id", sort: "desc" }]);
    const [orderDetails, setOrderDetails] = useState<IOrderDetails>(null);
    const [statuses, setStatuses] = useState<IReferential[]>([]);
    const [orderId, setOrderId] = useState<string>(null);

    useTimeout(() => setPagination((prev) => ({ ...prev, page: 0 })), [inputFilters]);

    useEffect(() => {
        getStatuses();
    }, []);

    useEffect(() => {
        getOrders();
    }, [pagination, sortModel]);

    const getOrders = async () => {
        const payload = {
            page: pagination.page,
            size: pagination.pageSize,
            sorts: sortModel.map((s) => `${s.field},${s.sort}`),
            ...createPayload(inputFilters),
        };
        const res = await VaccinationService.getOrders(payload);
        if (res.status === HttpStatus.OK) {
            setOrders(await res.json());
            setRowCount(+res.headers.get(CALYPSO_HEADERS.TABLE_COUNT));
        }
    };

    const getStatuses = async () => {
        const res = await VaccinationService.getOrderStatuses();
        if (res.status === HttpStatus.OK) {
            setStatuses(await res.json());
        }
    };

    const handleOpenDetails = async (id: string) => {
        const res = await VaccinationService.getOrderDetails(id);
        if (res.status === HttpStatus.OK) {
            setOrderDetails(await res.json());
        }
    };

    const columns = [
        {
            field: "id",
            headerName: "N° de commande",
            flex: 1,
        },
        {
            field: "orderDate",
            headerName: "Date commande",
            flex: 1,
            valueFormatter: (params: GridValueFormatterParams<string>) =>
                params.value ? DateTime.fromISO(params.value).toLocaleString() : "",
        },
        {
            field: "dpeId",
            headerName: "N° ordinal DPE",
            flex: 1,
        },
        {
            field: "dpeName",
            headerName: "Nom du DPE",
            flex: 1,
        },
        {
            field: "veterinaryId",
            headerName: "N° ordinal vétérinaire",
            flex: 1,
        },
        {
            field: "veterinaryLastname",
            headerName: "Vétérinaire",
            flex: 1,
            valueGetter: (params: GridRenderCellParams) =>
                `${params.row.veterinaryFirstname} ${params.row.veterinaryLastname}`,
        },
        {
            field: "vaccineLabel",
            headerName: "Vaccin",
            flex: 2,
            sortable: false,
        },
        {
            field: "quantity",
            headerName: "Quantité",
            flex: 1,
        },
        {
            field: "status",
            headerName: "Statut",
            flex: 1,
            valueFormatter: (params: GridValueFormatterParams) => statuses.find((s) => s.key === params.value)?.label,
            sortable: false,
        },
        {
            ...ActionsColumnProps,
            width: ACTIONS_COLUMN_WIDTH,
            renderCell: (params: GridRenderCellParams) => (
                <Box>
                    <ViewAction title="Voir le détail" onClick={() => handleOpenDetails(params.row.id)} />
                    {params.row.status === "TRANSMITTED" && (
                        <PermissionsCheck
                            requiredPermissions={[UserSituation.REGISTERED_IN_PRACTICE]}
                            preferences={[Preference.IAHP, Preference.CATTLE, Preference.SHEEP]}
                        >
                            <DeleteAction title="Annuler la commande" onClick={() => setOrderId(params.row.id)} />
                        </PermissionsCheck>
                    )}
                </Box>
            ),
        },
    ];

    const handlePageSizeChange = (pageSize: number) => {
        setPagination({ pageSize, page: 0 });
    };

    const handlePageChange = (page: number) => {
        setPagination({ ...pagination, page });
    };

    const filterConfigurations: FilterConfigurations<TrackingOrderFilters> = {
        id: { label: "N° de commande", type: FilterType.INPUT },
        orderDate: { label: "Date de la commande", type: FilterType.DATEPICKER },
        dpe: { label: "DPE", type: FilterType.INPUT },
        veterinary: { label: "Vétérinaire", type: FilterType.INPUT },
        vaccineGtinCode: {
            label: "Vaccin",
            type: FilterType.SELECT_AUTOCOMPLETE,
            values: vaccines.map((v) => ({ key: v.gtinCode, label: `${v.name} ${v.packaging}` })),
        },
        status: { label: "Statut", type: FilterType.SELECT, values: statuses },
    };

    return (
        <>
            <Stack
                spacing={2}
                sx={{
                    width: "100%",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                    }}
                >
                    <Typography variant="h4">Mes commandes de vaccins</Typography>
                    <PermissionsCheck
                        requiredPermissions={[UserSituation.REGISTERED_IN_PRACTICE]}
                        preferences={[Preference.IAHP, Preference.CATTLE, Preference.SHEEP]}
                    >
                        <GenericButton
                            onClick={() => navigate(routerLinks.vaccinationCampaign.vaccine.order())}
                            label="Nouvelle commande"
                            startIcon={<Add />}
                        />
                    </PermissionsCheck>
                </Box>

                <Card>
                    <CardContent>
                        <GenericFilters
                            inputFilters={inputFilters}
                            filterConfigurations={filterConfigurations}
                            initialValues={{
                                id: "",
                                orderDate: [null, null],
                                dpe: "",
                                veterinary: "",
                                status: [],
                                vaccineGtinCode: [],
                            }}
                            setInputFilters={setInputFilters}
                        />

                        <GenericTable
                            rows={orders}
                            columns={columns}
                            onPageSizeChange={handlePageSizeChange}
                            onPageChange={handlePageChange}
                            page={pagination.page}
                            pageSize={pagination.pageSize}
                            autoHeight
                            sortingMode="server"
                            paginationMode="server"
                            sortModel={sortModel}
                            rowCount={rowCount}
                            onSortModelChange={(model) => setSortModel(model)}
                            sortingOrder={["asc", "desc"]}
                            filterMode="server"
                        />
                    </CardContent>
                </Card>
            </Stack>
            {orderDetails && <DetailsDialog onClose={() => setOrderDetails(null)} order={orderDetails} />}
            {orderId && (
                <GenericDialog
                    onClose={() => setOrderId(null)}
                    title={`Annulation de la commande n° ${orderId}`}
                    renderContent={() => (
                        <>
                            <Typography>
                                Merci de contacter le distributeur du vaccin pour suivre ou annuler une commande.
                            </Typography>
                            <Typography>
                                Serviphar : <a href="mailto:admin@serviphar.com">admin@serviphar.com</a> /{" "}
                                <a href="tel:02 99 76 83 03">02 99 76 83 03</a>
                            </Typography>
                        </>
                    )}
                    renderActions={() => <GenericButton onClick={() => setOrderId(null)} label="Fermer" />}
                />
            )}
        </>
    );
}
