// === NPM
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useOutletContext } from "react-router-dom";
import { toast } from "react-toastify";
import { zodResolver } from "@hookform/resolvers/zod";
import { AddRounded } from "@mui/icons-material";
import { Box, Button, Card, Skeleton, Stack, Tooltip, Typography } from "@mui/material";
import { DateTime } from "luxon";
import { z } from "zod";
// === LOCAL
import GenericButton from "@/components/generics/buttons/GenericButton";
import GenericConfirmDialog from "@/components/generics/dialogs/GenericConfirmDialog";
import SecondaryButton from "@/components/styled/SecondaryButton";
import { StyledCardContent } from "@/components/styled/StyledCardContent";
import useEffectAfterMount from "@/hooks/useEffectAfterMount";
import { HttpStatus } from "@/interfaces/global";
import { IDpeShort } from "@/interfaces/user";
import { IVaccine, ShippingAddressType, VaccinationOutletContext, VaccineUsage } from "@/interfaces/vaccination";
import { maxIntegerValue } from "@/resources/AppConstant";
import { FORM_TEXT, PATTERN, stringRequired } from "@/resources/FormUtils";
import { getEnumKeyByValue, isArrayTruthy } from "@/resources/utils";
import { routerLinks } from "@/routers/RouterConstant";
import { useAuth } from "@/routers/useAuth";
import UserService from "@/services/UserService";
import VaccinationService from "@/services/VaccinationService";
import ConfirmDialog from "./containers/ConfirmDialog";
import ManualDelivery from "./containers/ManualDelivery";
import OrderInformations from "./containers/OrderInformations";
import ShippingInformations from "./containers/ShippingInformations";
import {
    DelivererType,
    IManualDelivery,
    IManualOrder,
    IOrder,
    IOrderRoot,
    IServipharForm,
    IServipharOrder,
    manualDeliveryInitialValues,
} from "./interface";

export const emptyOrderRoot: IOrderRoot = {
    type: null,
    dpeId: "",
    vaccineGtinCode: "",
    quantity: 0,
};

export const emptyOrder: IOrder = {
    requestedDeliveryDate: "",
    deliveryName: "",
    address: "",
    complementaryAddress: "",
    city: "",
    postalCode: "",
    phoneNumber: "",
    shippingAddressType: null,
    deliveries: null,
    type: null,
    dpeId: "",
    vaccineGtinCode: "",
    quantity: 0,
};

const initDate = (): DateTime => {
    const date = DateTime.now().plus({ days: 2 });
    if (date.weekday === 7) return date.plus({ days: 1 });
    return date;
};

export const emptyServipharOrder: IServipharForm = {
    requestedDeliveryDate: initDate().toISODate().toString(),
    deliveryName: null,
    address: null,
    complementaryAddress: null,
    city: "",
    postalCode: "",
    phoneNumber: null,
    shippingAddressType: ShippingAddressType.ORDER_DPE,
};

const orderInfoForm = z.object({
    dpeId: stringRequired(),
    vaccineGtinCode: stringRequired(),
    quantity: z.coerce
        .number({
            required_error: FORM_TEXT.required,
            invalid_type_error: "La quantité doit être un nombre entier strictement positif",
        })
        .positive("La quantité doit être un nombre entier strictement positif")
        .int("La quantité doit être un nombre entier strictement positif")
        .max(maxIntegerValue, FORM_TEXT.maxInteger),
});

export type OrderInformationSchema = z.infer<typeof orderInfoForm>;

const servipharZodForm = z.object({
    deliveryName: stringRequired().max(25, "Le nom du destinataire ne doit pas dépasser 25 caractères"),
    address: stringRequired().max(30, "L'adresse ne doit pas dépasser 30 caractères"),
    complementaryAddress: z
        .string()
        .max(30, "Le complément d'adresse ne doit pas dépasser 30 caractères")
        .refine((value) => !(value.trim().length === 0 && value.length > 0), FORM_TEXT.emptyString)
        .optional()
        .nullable(),
    city: stringRequired(),
    postalCode: stringRequired(),
    phoneNumber: stringRequired().regex(
        new RegExp(PATTERN.mobilePhoneNumber),
        "Le numéro de téléphone doit commencer par 06 ou 07 et ne pas avoir d'espace"
    ),
    requestedDeliveryDate: stringRequired()
        .refine(
            (value) => DateTime.fromISO(value) > DateTime.now().startOf("day"),
            "La date de livraison souhaitée doit être dans le futur"
        )
        .refine(
            (value) => DateTime.fromISO(value).weekday !== 7,
            "La date de livraison souhaitée ne peut être un dimanche"
        ),
    shippingAddressType: z.enum([
        ShippingAddressType.DIFFERENT,
        ShippingAddressType.ORDER_DPE,
        ShippingAddressType.SHIPPING_DPE,
    ]),
});

export type ServipharFormSchema = z.infer<typeof servipharZodForm>;

export default function Order() {
    const auth = useAuth();
    const { vaccines, loading } = useOutletContext<VaccinationOutletContext>();
    const navigate = useNavigate();
    const [dpe, setDpe] = useState<IDpeShort>(null);
    const [vaccine, setVaccine] = useState<IVaccine>(null);
    const [openCancelDialog, setOpenCancelDialog] = useState<boolean>(false);
    const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
    const [isValidServiphar, setIsValidServiphar] = useState<boolean>(false);
    const [isValidOrderInformation, setIsValidOrderInformation] = useState<boolean>(false);
    const [isValidDeliveries, setIsValidDeliveries] = useState<boolean[]>([]);
    const [manualDeliveries, setManualDeliveries] = useState<IManualDelivery[]>([]);
    const [quantityError, setQuantityError] = useState<string>(null);
    const orderInformationRef = useRef<HTMLButtonElement>(null);
    const manualDeliveryRef = useRef<Record<number, HTMLButtonElement>>({});
    const servipharRef = useRef<HTMLButtonElement>(null);

    const orderInformationForm = useForm<OrderInformationSchema>({
        resolver: zodResolver(orderInfoForm),
        defaultValues: emptyOrderRoot,
    });

    const dpeId = orderInformationForm.watch("dpeId");
    const vaccineGtinCode = orderInformationForm.watch("vaccineGtinCode");

    const servipharForm = useForm<ServipharFormSchema>({
        resolver: zodResolver(servipharZodForm),
        defaultValues: emptyServipharOrder,
    });

    useEffect(() => {
        if (dpeId) {
            getDpe();
        }
    }, [dpeId]);

    useEffectAfterMount(() => {
        if (vaccineGtinCode) {
            const newVaccine = vaccines.find((v) => v.gtinCode === vaccineGtinCode);
            const isManual = newVaccine?.deliverer === getEnumKeyByValue(DelivererType, DelivererType.OTHER);
            setVaccine(newVaccine);
            if (isManual) {
                servipharForm.reset({ ...emptyServipharOrder, requestedDeliveryDate: null });
                setManualDeliveries([
                    {
                        ...manualDeliveryInitialValues,
                        deliveredQuantity: orderInformationForm.getValues("quantity"),
                        internalId: crypto.randomUUID(),
                    },
                ]);
            } else {
                servipharForm.reset(emptyServipharOrder);
                setManualDeliveries([]);
            }
        }
    }, [vaccineGtinCode]);

    useEffect(() => {
        setIsValidDeliveries(Array(manualDeliveries.length).fill(false));
    }, [manualDeliveries.length]);

    useEffect(() => {
        const validManualDeliveries = isArrayTruthy(isValidDeliveries) && !quantityError;
        if (isValidOrderInformation && (isValidServiphar || validManualDeliveries)) {
            setOpenConfirmDialog(true);
            setIsValidOrderInformation(false);
            setIsValidServiphar(false);
            setIsValidDeliveries(Array(manualDeliveries.length).fill(false));
            setQuantityError(null);
        }
    }, [isValidOrderInformation, isValidServiphar, isValidDeliveries]);

    const checkManualDeliveriesSum = () => {
        const quantity = +orderInformationForm.getValues("quantity");
        if (vaccine && vaccine.deliverer === getEnumKeyByValue(DelivererType, DelivererType.SERVIPHAR)) {
            setQuantityError(null);
            return;
        }
        const deliveriesSum = manualDeliveries.reduce(
            (sum: number, delivery: IManualDelivery) => sum + +delivery.deliveredQuantity,
            0
        );
        if (deliveriesSum !== quantity) {
            setQuantityError("Les quantités livrées doivent correspondre");
        } else {
            setQuantityError(null);
        }
    };

    const getDpe = async () => {
        const res = await UserService.getDpe(dpeId);
        if (res.status === HttpStatus.OK) {
            setDpe(await res.json());
        }
    };

    const validateForm = () => {
        setIsValidOrderInformation(false);
        setIsValidServiphar(false);
        setQuantityError(null);
        setIsValidDeliveries(Array(manualDeliveries.length).fill(false));
        orderInformationRef.current?.click();
        servipharRef.current?.click();
        Object.values(manualDeliveryRef.current).forEach((manualDelivery) => manualDelivery?.click());
        if (vaccine && vaccine.deliverer === getEnumKeyByValue(DelivererType, DelivererType.OTHER))
            checkManualDeliveriesSum();
    };

    const createOrder = async () => {
        let newOrder: IServipharOrder | IManualOrder;
        const orderInformationData = orderInformationForm.getValues() as IOrderRoot;
        const servipharData = servipharForm.getValues() as IServipharForm;
        if (vaccine.deliverer === getEnumKeyByValue(DelivererType, DelivererType.SERVIPHAR)) {
            newOrder = {
                ...orderInformationData,
                ...servipharData,
                type: getEnumKeyByValue(DelivererType, DelivererType.SERVIPHAR),
            };
        } else {
            newOrder = {
                ...orderInformationData,
                deliveries: manualDeliveries,
                type: getEnumKeyByValue(DelivererType, DelivererType.OTHER),
            };
        }
        const res = await VaccinationService.postOrder(newOrder);
        if (res.status === HttpStatus.CREATED) {
            toast.success("La commande a bien été créée");
            navigate(routerLinks.vaccinationCampaign.vaccine.view());
        }
    };

    const handleConfirmCancel = (confirm: boolean) => {
        setOpenCancelDialog(false);
        if (confirm) navigate(routerLinks.vaccinationCampaign.vaccine.view(), { state: null });
    };

    const renderLoading = () => (
        <Stack
            spacing={4}
            sx={{
                width: "100%",
            }}
        >
            <Skeleton variant="text" height={20} />
            <Skeleton variant="rounded" height={250} />
            <Skeleton variant="text" height={20} />
            <Skeleton variant="rounded" height={450} />
        </Stack>
    );

    const handleAddManualDelivery = () => {
        if (manualDeliveries.length === 0) {
            setManualDeliveries([
                {
                    ...manualDeliveryInitialValues,
                    deliveredQuantity: orderInformationForm.getValues("quantity"),
                    internalId: crypto.randomUUID(),
                },
            ]);
        } else {
            const newDeliveries = [
                ...manualDeliveries,
                { ...manualDeliveryInitialValues, internalId: crypto.randomUUID() },
            ];
            setManualDeliveries(newDeliveries);
        }
    };

    const handleUpdateManualDelivery = (internalId: string, manualDelivery: IManualDelivery) => {
        const newDeliveries = [...manualDeliveries];
        const index = newDeliveries.findIndex((delivery) => delivery.internalId === internalId);
        newDeliveries[index] = { ...manualDelivery, internalId };
        setManualDeliveries(newDeliveries);
    };

    const handleDeleteManualDelivery = (internalId: string) => {
        const newDeliveries = manualDeliveries.filter((delivery) => delivery.internalId !== internalId);
        setManualDeliveries(newDeliveries);
    };

    const renderBatchDeliveries = () => (
        <Stack spacing={2}>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    p: 2,
                }}
            >
                <Typography variant="h5">Informations de livraison</Typography>
                <Tooltip title={isValidDeliveries.length >= 10 ? "Vous êtes limité à 10 livraisons" : ""}>
                    <span>
                        <GenericButton
                            label="Ajouter une livraison"
                            startIcon={<AddRounded />}
                            onClick={handleAddManualDelivery}
                            disabled={isValidDeliveries.length >= 10}
                        />
                    </span>
                </Tooltip>
            </Box>
            <Card>
                <StyledCardContent>
                    <Stack spacing={{ xs: 3, md: 1 }}>
                        {manualDeliveries.length === 0 && (
                            <Typography sx={{ textAlign: "center" }}>Ajoutez une livraison pour continuer.</Typography>
                        )}
                        {manualDeliveries?.map((delivery, index) => (
                            <ManualDelivery
                                key={delivery.internalId}
                                index={index}
                                manualDelivery={delivery}
                                handleDeleteManualDelivery={handleDeleteManualDelivery}
                                handleUpdateManualDelivery={handleUpdateManualDelivery}
                                manualDeliveryRef={manualDeliveryRef}
                                setIsValidDeliveries={setIsValidDeliveries}
                                quantityError={quantityError}
                                deliveriesLength={manualDeliveries.length}
                            />
                        ))}
                    </Stack>
                </StyledCardContent>
            </Card>
        </Stack>
    );

    const renderDeliveryInformation = () => {
        if (!vaccine) return;

        switch (vaccine.deliverer) {
            case getEnumKeyByValue(DelivererType, DelivererType.SERVIPHAR):
                return (
                    <>
                        <Typography variant="h5">Informations de livraison</Typography>
                        <ShippingInformations
                            authorizeOtherShippingAddress={vaccine.useKey === VaccineUsage.IAHP}
                            dpeError={orderInformationForm.formState?.errors?.dpeId?.message}
                            dpes={auth.userInfo.properties.dpes}
                            orderDpe={dpe}
                            servipharForm={servipharForm}
                            servipharRef={servipharRef}
                            onValid={() => setIsValidServiphar(true)}
                        />
                    </>
                );

            case getEnumKeyByValue(DelivererType, DelivererType.OTHER):
                return renderBatchDeliveries();
            default:
                return;
        }
    };

    const renderContent = () => (
        <Stack
            spacing={4}
            sx={{
                width: "100%",
            }}
        >
            <Typography variant="h4">Nouvelle commande de vaccins</Typography>
            <Typography variant="h5">Informations de commande</Typography>
            <OrderInformations
                dpes={auth.userInfo.properties.dpes}
                orderInformationRef={orderInformationRef}
                orderInformationForm={orderInformationForm}
                quantityError={quantityError}
                onValid={() => setIsValidOrderInformation(true)}
            />
            {renderDeliveryInformation()}
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    gap: 2,
                    width: "100%",
                }}
            >
                <SecondaryButton variant="outlined" onClick={() => setOpenCancelDialog(true)}>
                    Annuler
                </SecondaryButton>
                <Button onClick={validateForm} variant="contained">
                    Vérifier la commande
                </Button>
            </Box>
        </Stack>
    );

    return loading ? (
        renderLoading()
    ) : (
        <>
            {renderContent()}
            {openCancelDialog && (
                <GenericConfirmDialog
                    title="Annuler la saisie"
                    message="Êtes-vous sûr de vouloir annuler ? Vous allez perdre toutes les informations saisies."
                    onClose={handleConfirmCancel}
                />
            )}
            {openConfirmDialog && (
                <ConfirmDialog
                    onClose={() => setOpenConfirmDialog(false)}
                    onConfirm={createOrder}
                    orderInformationData={orderInformationForm.getValues() as IOrderRoot}
                    servipharData={servipharForm.getValues() as IServipharForm}
                    manualDeliveries={manualDeliveries}
                    dpes={auth.userInfo.properties.dpes}
                />
            )}
        </>
    );
}
