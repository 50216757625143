// === NPM
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Box } from "@mui/material";
import { GridRenderCellParams, GridSortModel } from "@mui/x-data-grid-pro";
import { DateTime } from "luxon";
// === LOCAL
import { ReactComponent as History } from "@/assets/icons/billing/history_action.svg";
import EditAction from "@/components/generics/actions/EditAction";
import IconActionButton from "@/components/generics/actions/IconActionButton";
import ViewAction from "@/components/generics/actions/ViewAction";
import PermissionsCheck from "@/components/generics/PermissionsCheck";
import GenericTable from "@/components/generics/table/GenericTable";
import { ActionsColumnProps, HttpStatus, IPagination } from "@/interfaces/global";
import { IVaccinationSite, VaccinationSiteUserType } from "@/interfaces/vaccination";
import { colors } from "@/resources/CssConstant";
import { Preference, UserSituation } from "@/resources/PermissionConstant";
import { hasAccess } from "@/resources/utils";
import { useAuth } from "@/routers/useAuth";
import VaccinationService from "@/services/VaccinationService";
import {
    BillingStatus,
    billingStatusOptionsStates,
    IBillable,
    IBillingStatusDtoOut,
    IStatusChange,
} from "../../interface";
import StatusChangesDialog from "./StatusChangesDialog";
import VaccinationSiteBillingStatusDialog from "./VaccinationSiteBillingStatusDialog";

interface VaccinationSitesDataGridProps {
    outsideCalypso: boolean;
    billables: IBillable[];
    sortModel: GridSortModel;
    setSortModel: Dispatch<SetStateAction<GridSortModel>>;
    rowCount: number;
    pagination: IPagination;
    setPagination: Dispatch<SetStateAction<IPagination>>;
    setVaccinationSiteId: Dispatch<SetStateAction<number>>;
    setVaccinationSiteDate: Dispatch<SetStateAction<string>>;
    getBillableCount?: () => void;
    getBillables: () => void;
}

export default function VaccinationSitesDataGrid({
    outsideCalypso,
    billables,
    sortModel,
    setSortModel,
    rowCount,
    pagination,
    setPagination,
    getBillableCount,
    getBillables,
    setVaccinationSiteId,
    setVaccinationSiteDate,
}: Readonly<VaccinationSitesDataGridProps>) {
    const auth = useAuth();
    let userType = "other";
    if (hasAccess(auth.userInfo.situation, [UserSituation.USER_DDPP, UserSituation.ADMIN_DDPP])) {
        userType = "userAdminDDPP";
    } else if (
        hasAccess(auth.userInfo.situation, [UserSituation.REGISTERED_IN_PRACTICE]) &&
        auth.userInfo.preferences[Preference.IAHP]
    ) {
        userType = "userVet";
    }

    const [viewMode, setViewMode] = useState<boolean>(false);
    const [openBillingStatusViewEditDialog, setOpenBillingStatusViewEditDialog] = useState<boolean>(false);
    const [openStatusChangesDialog, setOpenStatusChangesDialog] = useState<boolean>(false);
    const [vaccinationSite, setVaccinationSite] = useState<IVaccinationSite>(null);
    const [selectedBillable, setSelectedBillable] = useState<IBillable>(null);
    const [statusChanges, setStatusChanges] = useState<IStatusChange[]>([]);

    useEffect(() => {
        setSelectedBillable(null);
    }, [billables]);

    const viewBillingStatusDialog = async (billable: IBillable) => {
        const res = await VaccinationService.getVaccinationSite(billable.id);
        if (res.status === HttpStatus.OK) {
            setVaccinationSite(await res.json());
            setSelectedBillable(billable);
            setOpenBillingStatusViewEditDialog(true);
        }
    };

    const editBillingStatus = async (id: number, data: IBillingStatusDtoOut) => {
        const res = await VaccinationService.patchBillingStatus(id, data);
        if (res.status === HttpStatus.NO_CONTENT) {
            getBillableCount && getBillableCount();
            getBillables();
            setOpenBillingStatusViewEditDialog(false);
            setVaccinationSiteId(null);
        }
    };

    const getStatusChanges = async (billable: IBillable) => {
        const res = await VaccinationService.getVaccinationSiteStatusChanges(billable.id);
        if (res.status === HttpStatus.OK) {
            setStatusChanges(await res.json());
            setSelectedBillable(billable);
            setOpenStatusChangesDialog(true);
        }
    };

    const handlePageSizeChange = (pageSize: number) => {
        setPagination({ pageSize, page: 0 });
    };

    const handlePageChange = (page: number) => {
        setPagination({ ...pagination, page });
    };

    const columns = [
        {
            field: "id",
            headerName: "Identifiant chantier",
            flex: 1,
        },
        {
            field: "date",
            headerName: "Date du chantier",
            flex: 1,
            valueGetter: (params: GridRenderCellParams) => DateTime.fromISO(params.row.date).toLocaleString(),
        },
        {
            field: "userType",
            headerName: "Type de vaccinateur",
            flex: 1,
            sortable: false,
            valueGetter: (params: GridRenderCellParams) => VaccinationSiteUserType[params.row.userType],
        },
        {
            field: "dpeId",
            headerName: "DPE",
            flex: 1,
            valueGetter: (params: GridRenderCellParams) => `${params.row.dpeId} - ${params.row.dpeName}`,
        },
        {
            field: "veterinaryId",
            headerName: "Vétérinaire",
            flex: 1,
            valueGetter: (params: GridRenderCellParams) =>
                `${params.row.veterinaryId} - ${params.row.veterinaryLastname} ${params.row.veterinaryFirstname}`,
        },
        {
            field: "farmId",
            headerName: "Identifiant Établissement",
            flex: 1,
        },
        {
            field: "hasAudit",
            headerName: "Audit",
            flex: 1,
            sortable: false,
            valueGetter: (params: GridRenderCellParams) => (params.row.hasAudit ? "Oui" : "Non"),
        },
        {
            field: "nonCompliant",
            headerName: "Non Conformité Majeure",
            flex: 1,
            sortable: false,
            valueGetter: (params: GridRenderCellParams) => (params.row.nonCompliant ? "Oui" : "Non"),
        },
        {
            field: "animalCount",
            headerName: "Nombre de canards vaccinés",
            flex: 1,
            sortable: false,
        },
        {
            field: "doseCount",
            headerName: "Nombre de flacons utilisés",
            flex: 1,
            sortable: false,
        },
        {
            field: "billingStatus",
            headerName: "Statut facturation",
            flex: 1,
            sortable: false,
            hide: outsideCalypso,
            valueGetter: (params: GridRenderCellParams) => BillingStatus[params.row.billingStatus],
        },
        {
            ...ActionsColumnProps,
            width: 200,
            renderCell: (params: GridRenderCellParams) => (
                <Box>
                    <ViewAction
                        title="Voir le détail"
                        onClick={(event) => {
                            event.stopPropagation();
                            setViewMode(true);
                            viewBillingStatusDialog(params.row);
                        }}
                    />
                    {billingStatusOptionsStates[params.row.billingStatus][userType].length > 1 && (
                        <PermissionsCheck
                            requiredPermissions={[
                                UserSituation.REGISTERED_IN_PRACTICE,
                                UserSituation.ADMIN_DDPP,
                                UserSituation.USER_DDPP,
                            ]}
                            preferences={[Preference.IAHP]}
                        >
                            <EditAction
                                title="Modifier le statut du chantier"
                                onClick={(event) => {
                                    event.stopPropagation();
                                    setViewMode(false);
                                    viewBillingStatusDialog(params.row);
                                }}
                            />
                        </PermissionsCheck>
                    )}

                    <IconActionButton
                        title="Visualiser l'historique"
                        icon={<History />}
                        onClick={(event) => {
                            event.stopPropagation();
                            getStatusChanges(params.row);
                        }}
                    />
                </Box>
            ),
        },
    ];

    return (
        <Box
            sx={{
                "& .selected": {
                    backgroundColor: colors.background,
                },
            }}
        >
            <GenericTable
                rows={billables}
                columns={columns}
                getRowId={(row) => row.id}
                autoHeight
                paginationMode="server"
                page={pagination.page}
                pageSize={pagination.pageSize}
                onPageSizeChange={handlePageSizeChange}
                onPageChange={handlePageChange}
                rowCount={rowCount}
                sortingMode="server"
                sortModel={sortModel}
                sortingOrder={["asc", "desc"]}
                onSortModelChange={(model) => setSortModel(model)}
                filterMode="server"
                onRowClick={(params) => {
                    setSelectedBillable(params.row);
                    setVaccinationSiteId(params.row.id);
                    setVaccinationSiteDate(params.row.date);
                }}
                getRowClassName={(params) => (params.row.id === selectedBillable?.id ? "selected" : "")}
            />
            {openBillingStatusViewEditDialog && (
                <VaccinationSiteBillingStatusDialog
                    view={viewMode}
                    vaccinationSite={vaccinationSite}
                    billable={selectedBillable}
                    editBillingStatus={editBillingStatus}
                    onClose={() => {
                        setOpenBillingStatusViewEditDialog(false);
                        setVaccinationSite(null);
                        setSelectedBillable(null);
                        setViewMode(null);
                    }}
                />
            )}
            {openStatusChangesDialog && (
                <StatusChangesDialog
                    vaccinationSiteId={selectedBillable.id}
                    statusChanges={statusChanges}
                    onClose={() => {
                        setStatusChanges([]);
                        setOpenStatusChangesDialog(false);
                    }}
                />
            )}
        </Box>
    );
}
