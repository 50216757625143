// === NPM
import { KyResponse } from "ky";
import { IHealthAccreditationTrainingSessionTraceabilityFilters } from "@/components/Administration/containers/Traceability/containers/HealthAccreditationTrainingSessionTraceability/HealthAccreditationTrainingSessionTraceability";
// === LOCAL
import {
    IAgencyTraceability,
    IContinuousTrainingOrganizationTraceability,
    IHealthAccreditationTrainingSessionTraceability,
    IHealthAccreditationTrainingSessionTraceabilityDetails,
    IHealthAccreditationTrainingTraceability,
    IHealthAccreditationTrainingTraceabilityDetails,
    IHealthReportingTraceability,
    IHealthReportingTraceabilityFilters,
    INonDeliveryTraceability,
    IOvvtTraceability,
    IOvvtTraceabilityDetails,
    IUserTraceability,
} from "@/components/Administration/containers/Traceability/interface";
import {
    IMonitoringInterventionTraceability,
    IScrappingTraceability,
    IVaccinationInterventionTraceability,
    IVaccinationSiteTraceability,
    MonitoringInterventionTraceabilityDetails,
    ScrappingTraceabilityDetailsType,
    VaccinationSiteTraceabilityDetailsType,
} from "@/components/VaccinationIahp/TraceabilityIahp/interface";
import { HttpMethod, Loader, SearchParams } from "@/interfaces/global";
import { IReferential } from "@/interfaces/referential";
import { IDefaultTraceabilityFilters } from "@/interfaces/traceability";
import { formatParams } from "@/resources/utils";
import { endpoints } from "./EndpointConstant";
import HttpService from "./HttpService";

class TraceabilityService {
    async getTraceabilityContinuousTrainingOrganization(
        payload: SearchParams &
            Partial<{
                date: string[];
                authorId: string;
                action: string;
            }>
    ): Promise<KyResponse<IContinuousTrainingOrganizationTraceability[]>> {
        return HttpService.fetch(endpoints.traceabilityService.continuousTrainingOrganization(), {
            method: HttpMethod.GET,
            searchParams: formatParams(payload),
            headers: {
                loader: Loader.TABLE,
            },
        });
    }
    async getTraceabilityUsers(
        payload: SearchParams &
            Partial<{
                date: string[];
                authorId: string;
                action: string;
            }>
    ): Promise<KyResponse<IUserTraceability[]>> {
        return HttpService.fetch(endpoints.traceabilityService.users(), {
            method: HttpMethod.GET,
            searchParams: formatParams(payload),
            headers: {
                loader: Loader.TABLE,
            },
        });
    }

    async getTraceabilityAgencies(
        payload: SearchParams &
            Partial<{
                date: string[];
                authorId: string;
                action: string;
            }>
    ): Promise<KyResponse<IAgencyTraceability[]>> {
        return HttpService.fetch(endpoints.traceabilityService.agencies(), {
            method: HttpMethod.GET,
            searchParams: formatParams(payload),
            headers: {
                loader: Loader.TABLE,
            },
        });
    }

    async getTraceabilityNonDeliveries(
        payload: SearchParams &
            Partial<{
                date: string[];
                authorId: string;
                action: string;
                year: string;
                authorizationNumber: string;
                quarter: string;
            }>
    ): Promise<KyResponse<INonDeliveryTraceability[]>> {
        return HttpService.fetch(endpoints.traceabilityService.nonDeliveries(), {
            method: HttpMethod.GET,
            searchParams: formatParams(payload),
            headers: {
                loader: Loader.TABLE,
            },
        });
    }

    async getTraceabilityVaccinationSite(
        payload: SearchParams &
            Partial<{ type: string; vaccinationSiteId: string; interventionId: string & IDefaultTraceabilityFilters }>
    ): Promise<KyResponse<IVaccinationSiteTraceability[]>> {
        return HttpService.fetch(endpoints.traceabilityService.vaccinationSites.base(), {
            method: HttpMethod.GET,
            searchParams: formatParams(payload),
            headers: {
                loader: Loader.TABLE,
            },
        });
    }

    async getTraceabilityVaccinationSiteDetails(
        uuid: string
    ): Promise<KyResponse<VaccinationSiteTraceabilityDetailsType>> {
        return HttpService.fetch(endpoints.traceabilityService.vaccinationSites.details(uuid), {
            method: HttpMethod.GET,
        });
    }

    async getTraceabilityVaccinationSiteFile(uuid: string, fileUuid: string): Promise<KyResponse> {
        return HttpService.fetch(endpoints.traceabilityService.vaccinationSites.file(uuid, fileUuid), {
            method: HttpMethod.GET,
        });
    }

    async getTraceabilityActions(type: string): Promise<KyResponse<IReferential[]>> {
        return HttpService.fetch(endpoints.traceabilityService.referential.actions(), {
            method: HttpMethod.GET,
            searchParams: formatParams({ type }),
        });
    }

    async getTraceabilityMonitoringIntervention(
        payload: SearchParams & Partial<{ interventionId: string & IDefaultTraceabilityFilters }>
    ): Promise<KyResponse<IMonitoringInterventionTraceability[]>> {
        return HttpService.fetch(endpoints.traceabilityService.monitoringIntervention(), {
            method: HttpMethod.GET,
            searchParams: formatParams(payload),
            headers: {
                loader: Loader.TABLE,
            },
        });
    }

    async getTraceabilityMonitoringInterventionFile(uuid: string, fileUuid: string): Promise<KyResponse> {
        return HttpService.fetch(endpoints.traceabilityService.monitoringInterventionFile(uuid, fileUuid), {
            method: HttpMethod.GET,
        });
    }

    async getTraceabilityScrapping(
        payload: SearchParams & Partial<{ scrappingId: string; dpe: string & IDefaultTraceabilityFilters }>
    ): Promise<KyResponse<IScrappingTraceability[]>> {
        return HttpService.fetch(endpoints.traceabilityService.scrapping.base, {
            method: HttpMethod.GET,
            searchParams: formatParams(payload),
            headers: {
                loader: Loader.TABLE,
            },
        });
    }

    async getTraceabilityScrappingDetails(uuid: string): Promise<KyResponse<ScrappingTraceabilityDetailsType>> {
        return HttpService.fetch(endpoints.traceabilityService.scrapping.details(uuid), {
            method: HttpMethod.GET,
        });
    }

    async getTraceabilityMonitoringInterventionDetails(
        uuid: string
    ): Promise<KyResponse<MonitoringInterventionTraceabilityDetails>> {
        return HttpService.fetch(endpoints.traceabilityService.monitoringInterventionDetails(uuid), {
            method: HttpMethod.GET,
        });
    }

    async getTraceabilityHealthReportings(
        payload: SearchParams & Partial<IHealthReportingTraceabilityFilters>
    ): Promise<KyResponse<IHealthReportingTraceability[]>> {
        return HttpService.fetch(endpoints.traceabilityService.healthReportings(), {
            method: HttpMethod.GET,
            searchParams: formatParams(payload),
            headers: {
                loader: Loader.TABLE,
            },
        });
    }

    async getUserSituations(): Promise<KyResponse<IReferential[]>> {
        return HttpService.fetch(endpoints.traceabilityService.referential.userSituations(), {
            method: HttpMethod.GET,
        });
    }

    async getHealthReportingStatuses(): Promise<KyResponse<IReferential[]>> {
        return HttpService.fetch(endpoints.traceabilityService.referential.healthReportingStatuses(), {
            method: HttpMethod.GET,
        });
    }

    async getTraceabilityOvvt(
        payload: SearchParams & Partial<IDefaultTraceabilityFilters>
    ): Promise<KyResponse<IOvvtTraceability[]>> {
        return HttpService.fetch(endpoints.traceabilityService.ovvt(), {
            method: HttpMethod.GET,
            searchParams: formatParams(payload),
            headers: {
                loader: Loader.TABLE,
            },
        });
    }

    async getTraceabilityOvvtDetails(uuid: string): Promise<KyResponse<IOvvtTraceabilityDetails>> {
        return HttpService.fetch(endpoints.traceabilityService.ovvtDetails(uuid), {
            method: HttpMethod.GET,
        });
    }

    async getTraceabilityHealthAccreditationTrainings(
        payload: SearchParams & Partial<IHealthReportingTraceabilityFilters>
    ): Promise<KyResponse<IHealthAccreditationTrainingTraceability[]>> {
        return HttpService.fetch(endpoints.traceabilityService.healthAccreditationTrainings(), {
            method: HttpMethod.GET,
            searchParams: formatParams(payload),
            headers: {
                loader: Loader.TABLE,
            },
        });
    }

    async getTraceabilityHealthAccreditationTrainingDetails(
        uuid: string
    ): Promise<KyResponse<IHealthAccreditationTrainingTraceabilityDetails>> {
        return HttpService.fetch(endpoints.traceabilityService.healthAccreditationTrainingsDetails(uuid), {
            method: HttpMethod.GET,
        });
    }

    async getTraceabilityHealthAccreditationTrainingSessions(
        payload: SearchParams & Partial<IHealthAccreditationTrainingSessionTraceabilityFilters>
    ): Promise<KyResponse<IHealthAccreditationTrainingSessionTraceability[]>> {
        return HttpService.fetch(endpoints.traceabilityService.healthAccreditationTrainingSessions(), {
            method: HttpMethod.GET,
            searchParams: formatParams(payload),
            headers: {
                loader: Loader.TABLE,
            },
        });
    }

    async getTraceabilityHealthAccreditationTrainingSessionDetails(
        uuid: string
    ): Promise<KyResponse<IHealthAccreditationTrainingSessionTraceabilityDetails>> {
        return HttpService.fetch(endpoints.traceabilityService.healthAccreditationTrainingSessionsDetails(uuid), {
            method: HttpMethod.GET,
        });
    }
}

export default new TraceabilityService();
