// === NPM
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Add } from "@mui/icons-material";
import { Box, Card, CardContent, Stack, Typography } from "@mui/material";
import { GridRenderCellParams, GridSortModel } from "@mui/x-data-grid-pro";
import { DateTime } from "luxon";
// === LOCAL
import DeleteAction from "@/components/generics/actions/DeleteAction";
import ViewAction from "@/components/generics/actions/ViewAction";
import GenericButton from "@/components/generics/buttons/GenericButton";
import GenericConfirmDialog from "@/components/generics/dialogs/GenericConfirmDialog";
import { FilterConfigurations, GenericFilters } from "@/components/generics/filters/GenericFilters";
import PermissionsCheck from "@/components/generics/PermissionsCheck";
import GenericTable from "@/components/generics/table/GenericTable";
import useTimeout from "@/hooks/useTimeout";
import { ActionsColumnProps, FilterType, HttpStatus, IPagination } from "@/interfaces/global";
import { IVaccinationSite, VaccinationSiteUserType } from "@/interfaces/vaccination";
import { CALYPSO_HEADERS, defaultPagination } from "@/resources/AppConstant";
import { UserSituation } from "@/resources/PermissionConstant";
import { convertEnumToKeyLabelObject, createPayload } from "@/resources/utils";
import VaccinationService from "@/services/VaccinationService";
import { IBillable, IBillableOutsideCalypsoFilters, ICreateMemory } from "../../interface";
import VaccinationSiteBillingStatusDialog from "../../VaccinationSites/containers/VaccinationSiteBillingStatusDialog";
import CreateUpdateMemoryDialog from "./MemoryDialog";

interface LinkedVaccinationSitesProps {
    memoryId: number;
    memoryTitle: string;
    dpeId: string;
    canDeleteVaccinationSites: boolean;
}

export default function LinkedVaccinationSites({
    memoryId,
    memoryTitle,
    dpeId,
    canDeleteVaccinationSites,
}: Readonly<LinkedVaccinationSitesProps>) {
    const initialValues = {
        id: "",
        date: [],
        userType: [],
        dpe: "",
        veterinary: "",
        farmId: "",
    };

    const [bills, setBills] = useState<IBillable[]>([]);
    const [inputFilters, setInputFilters] = useState<IBillableOutsideCalypsoFilters>(initialValues);
    const [pagination, setPagination] = useState<IPagination>(defaultPagination);
    const [rowCount, setRowCount] = useState<number>(0);
    const [sortModel, setSortModel] = useState<GridSortModel>([{ field: "id", sort: "desc" }]);
    const [openViewDialog, setOpenViewDialog] = useState<boolean>(false);
    const [selectedBillable, setSelectedBillable] = useState<IBillable>();
    const [vaccinationSite, setVaccinationSite] = useState<IVaccinationSite>(null);
    const [vaccinationSiteId, setVaccinationSiteId] = useState<number>(null);
    const [openEditDialog, setOpenEditDialog] = useState<boolean>(false);

    useEffect(() => {
        getBillables();
    }, [pagination, sortModel, memoryId]);

    useTimeout(() => setPagination((prev) => ({ ...prev, page: 0 })), [inputFilters]);

    const getBillables = async () => {
        const res = await VaccinationService.getMemoryBillables(memoryId, {
            page: pagination.page,
            size: pagination.pageSize,
            sorts: sortModel.map((s) => `${s.field},${s.sort}`),
            ...createPayload(inputFilters),
        });
        if (res.status === HttpStatus.OK) {
            setBills(await res.json());
            setRowCount(+res.headers.get(CALYPSO_HEADERS.TABLE_COUNT));
        }
    };

    const handlePageSizeChange = (pageSize: number) => {
        setPagination({ pageSize, page: 0 });
    };

    const handlePageChange = (page: number) => {
        setPagination({ ...pagination, page });
    };

    const viewVaccinationSiteDetails = async (billable: IBillable) => {
        const res = await VaccinationService.getVaccinationSite(billable.id);
        if (res.status === HttpStatus.OK) {
            setVaccinationSite(await res.json());
            setSelectedBillable(billable);
            setOpenViewDialog(true);
        }
    };

    const removeVaccinationSite = async (confirm: boolean) => {
        if (!confirm) {
            setVaccinationSiteId(null);
            return;
        }
        const res = await VaccinationService.removeVaccinationSiteFromMemory(memoryId, vaccinationSiteId);
        if (res.status === HttpStatus.NO_CONTENT) {
            setVaccinationSiteId(null);
            toast.success("Chantier retiré avec succès");
            getBillables();
        }
    };

    const handleUpdateMemory = async (confirm: boolean, newMemory: ICreateMemory) => {
        if (!confirm) {
            setOpenEditDialog(false);
            return;
        }
        const res = await VaccinationService.addVaccinationSitesToMemory(memoryId, newMemory.vaccinationSiteIds);
        if (res.status === HttpStatus.NO_CONTENT) {
            toast.success("Le mémoire a bien été modifié");
            setOpenEditDialog(false);
            getBillables();
        }
    };

    const filterConfigurations: FilterConfigurations<IBillableOutsideCalypsoFilters> = {
        id: {
            label: "Identifiant chantier",
            type: FilterType.NUMBER,
        },
        date: {
            label: "Date",
            type: FilterType.DATEPICKER,
        },
        userType: {
            label: "Type de vaccinateur",
            type: FilterType.SELECT,
            values: convertEnumToKeyLabelObject(VaccinationSiteUserType),
        },
        dpe: {
            label: "N° DPE",
            type: FilterType.INPUT,
        },
        veterinary: {
            label: "N° ordinal vétérinaire",
            type: FilterType.INPUT,
        },
        farmId: {
            label: "ID Établissement",
            type: FilterType.INPUT,
        },
    };
    const columns = [
        {
            field: "id",
            headerName: "Identifiant chantier",
            flex: 1,
        },
        {
            field: "date",
            headerName: "Date du chantier",
            flex: 1,
            valueGetter: (params: GridRenderCellParams) => DateTime.fromISO(params.row.date).toLocaleString(),
        },
        {
            field: "userType",
            headerName: "Type de vaccinateur",
            flex: 1,
            sortable: false,
            valueGetter: (params: GridRenderCellParams) => VaccinationSiteUserType[params.row.userType],
        },
        {
            field: "dpeId",
            headerName: "DPE",
            flex: 1,
            valueGetter: (params: GridRenderCellParams) => `${params.row.dpeId} - ${params.row.dpeName}`,
        },
        {
            field: "veterinaryId",
            headerName: "Vétérinaire",
            flex: 1,
            valueGetter: (params: GridRenderCellParams) =>
                `${params.row.veterinaryId} - ${params.row.veterinaryLastname} ${params.row.veterinaryFirstname}`,
        },
        {
            field: "farmId",
            headerName: "ID Établissement",
            flex: 1,
        },
        {
            field: "animalCount",
            headerName: "Nombre de canards vaccinés",
            flex: 1,
            sortable: false,
        },
        {
            field: "doseCount",
            headerName: "Nombre de flacons utilisés",
            flex: 1,
            sortable: false,
        },
        {
            field: "hasAudit",
            headerName: "Audit",
            flex: 1,
            sortable: false,
            valueGetter: (params: GridRenderCellParams) => (params.row.hasAudit ? "Oui" : "Non"),
        },
        {
            field: "nonCompliant",
            headerName: "NC Majeure",
            flex: 1,
            sortable: false,
            valueGetter: (params: GridRenderCellParams) => (params.row.nonCompliant ? "Oui" : "Non"),
        },
        {
            ...ActionsColumnProps,
            width: 150,
            renderCell: (params: GridRenderCellParams) => (
                <Box>
                    <ViewAction title="Voir le détail" onClick={() => viewVaccinationSiteDetails(params.row)} />
                    {canDeleteVaccinationSites && (
                        <PermissionsCheck requiredPermissions={[UserSituation.ADMIN_DDPP, UserSituation.USER_DDPP]}>
                            <DeleteAction
                                title="Retirer le chantier du mémoire"
                                onClick={() => setVaccinationSiteId(params.row.id)}
                            />
                        </PermissionsCheck>
                    )}
                </Box>
            ),
        },
    ];

    return (
        <>
            <Stack spacing={2}>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                    }}
                >
                    <Typography variant="h4">{`Suivi des chantiers du mémoire n°${memoryId}`}</Typography>
                    <PermissionsCheck requiredPermissions={[UserSituation.USER_DDPP, UserSituation.ADMIN_DDPP]}>
                        <GenericButton
                            onClick={() => setOpenEditDialog(true)}
                            label="Ajouter un chantier"
                            startIcon={<Add />}
                        />
                    </PermissionsCheck>
                </Box>
                <Card>
                    <CardContent>
                        <GenericFilters
                            inputFilters={inputFilters}
                            filterConfigurations={filterConfigurations}
                            initialValues={initialValues}
                            setInputFilters={setInputFilters}
                        />

                        <GenericTable
                            rows={bills}
                            columns={columns}
                            getRowId={(row) => row.id}
                            autoHeight
                            paginationMode="server"
                            page={pagination.page}
                            pageSize={pagination.pageSize}
                            onPageSizeChange={handlePageSizeChange}
                            onPageChange={handlePageChange}
                            rowCount={rowCount}
                            sortingMode="server"
                            sortModel={sortModel}
                            sortingOrder={["asc", "desc"]}
                            onSortModelChange={(model) => setSortModel(model)}
                            filterMode="server"
                        />
                    </CardContent>
                </Card>
            </Stack>
            {openViewDialog && (
                <VaccinationSiteBillingStatusDialog
                    view={true}
                    vaccinationSite={vaccinationSite}
                    billable={selectedBillable}
                    onClose={() => {
                        setOpenViewDialog(false);
                        setVaccinationSite(null);
                        setSelectedBillable(null);
                    }}
                />
            )}
            {vaccinationSiteId && (
                <GenericConfirmDialog
                    title={`Retirer le chantier n°${vaccinationSiteId} du mémoire n°${memoryId}`}
                    message={
                        'Êtes-vous sûr de vouloir retirer ce chantier ? Cette action n’est pas réversible.\n\nLe chantier prendra le statut "Validé DDPP".'
                    }
                    onClose={removeVaccinationSite}
                />
            )}
            {openEditDialog && (
                <CreateUpdateMemoryDialog
                    onClose={handleUpdateMemory}
                    memoryId={memoryId}
                    title={memoryTitle}
                    dpeId={dpeId}
                />
            )}
        </>
    );
}
