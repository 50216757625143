// === NPM
import React, { RefObject } from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import { useOutletContext } from "react-router-dom";
import { KeyboardArrowDown } from "@mui/icons-material";
import {
    Box,
    Card,
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
// === LOCAL
import { ReactComponent as Info } from "@/assets/icons/info.svg";
import GenericSelect from "@/components/generics/inputs/GenericSelect";
import GenericTextField from "@/components/generics/inputs/GenericTextField";
import { StyledCardContent } from "@/components/styled/StyledCardContent";
import { filterVaccines } from "@/components/VaccinationCampaign/utils";
import { IDpeProperties } from "@/interfaces/user";
import { VaccinationOutletContext } from "@/interfaces/vaccination";
import { defaultIntegerLimit } from "@/resources/AppConstant";
import { colors } from "@/resources/CssConstant";
import { useAuth } from "@/routers/useAuth";
import { DelivererType } from "../interface";
import { OrderInformationSchema } from "../Order";

interface OrderInformationsProps {
    dpes: IDpeProperties[];
    orderInformationRef: RefObject<HTMLButtonElement>;
    orderInformationForm: UseFormReturn<OrderInformationSchema>;
    quantityError: string;
    onValid: () => void;
}

export default function OrderInformations({
    dpes,
    orderInformationRef,
    orderInformationForm,
    quantityError,
    onValid,
}: Readonly<OrderInformationsProps>) {
    const { vaccines } = useOutletContext<VaccinationOutletContext>();
    const { userInfo } = useAuth();
    const {
        formState: { errors },
        register,
        handleSubmit,
        watch,
        control,
    } = orderInformationForm;

    const vaccineGtinCode = watch("vaccineGtinCode");
    const quantity = watch("quantity");
    const dpeId = watch("dpeId");
    const selectedVaccine = vaccines.find((v) => v.gtinCode === vaccineGtinCode);
    const hasCattleOrSheepPref = userInfo.preferences?.cattle || userInfo.preferences?.sheep;

    const filterDpes = () => {
        if (!hasCattleOrSheepPref) {
            return dpes.filter((dpe) => dpe.sanitaryDpe);
        }
        return dpes;
    };

    return (
        <Card>
            <StyledCardContent>
                <Stack
                    spacing={2}
                    sx={{
                        p: 2,
                    }}
                >
                    <Typography variant="bold">Informations sur les vaccins FCO</Typography>
                    <Stack
                        spacing={1}
                        direction="row"
                        sx={{
                            alignItems: "start",
                            py: 1,
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                color: colors.primaryColor,
                            }}
                        >
                            <Info />
                        </Box>
                        <Typography
                            variant="bold"
                            sx={{
                                color: colors.primaryColor,
                            }}
                        >
                            {
                                "La commande des vaccins BLUEVAC3 et BULTAVO3 ne peut être effectuée que pour l'utilisation suivante :\n- Le responsable de la commande et de la délivrance des vaccins est le vétérinaire sanitaire.\n- Le BLUEVAC3 100ml et 252 ml est destiné aux bovins. Le BULTAVO3 50 ml est destiné aux ovins."
                            }
                        </Typography>
                    </Stack>
                    <Typography variant="bold">Informations sur les vaccins MHE</Typography>
                    <Stack
                        spacing={1}
                        direction="row"
                        sx={{
                            alignItems: "start",
                            py: 1,
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                color: colors.primaryColor,
                            }}
                        >
                            <Info />
                        </Box>
                        <Typography
                            variant="bold"
                            sx={{
                                color: colors.primaryColor,
                            }}
                        >
                            {
                                "La commande du vaccin HEPIZOVAC ne peut être effectuée que pour l'utilisation suivante :\n- Le responsable de la commande et de la délivrance des vaccins est le vétérinaire sanitaire."
                            }
                        </Typography>
                    </Stack>
                    <Typography variant="bold">Informations du DPE</Typography>
                    {hasCattleOrSheepPref && (
                        <Stack
                            spacing={1}
                            direction="row"
                            sx={{
                                alignItems: "center",
                                py: 1,
                            }}
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    color: colors.primaryColor,
                                }}
                            >
                                <Info />
                            </Box>
                            <Typography
                                variant="bold"
                                sx={{
                                    color: colors.primaryColor,
                                }}
                            >
                                DPE en charge du suivi sanitaire
                            </Typography>
                        </Stack>
                    )}

                    <FormControl fullWidth error={!!errors.dpeId} required>
                        <InputLabel>Nom du DPE</InputLabel>
                        <Controller
                            name="dpeId"
                            control={control}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <>
                                    <Select
                                        label="Nom du DPE"
                                        onChange={onChange}
                                        value={value}
                                        IconComponent={KeyboardArrowDown}
                                    >
                                        {filterDpes().map((dpe) => (
                                            <MenuItem key={dpe.id} value={dpe.id}>
                                                {`${dpe.id} - ${dpe.name} - ${dpe.postalCode} - ${dpe.city}`}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <FormHelperText>{error?.message}</FormHelperText>
                                </>
                            )}
                        />
                    </FormControl>
                    <Typography variant="bold">Commande de vaccins</Typography>
                    <Stack direction="row" spacing={2}>
                        <Controller
                            name="vaccineGtinCode"
                            control={control}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <GenericSelect
                                    onChange={onChange}
                                    options={filterVaccines(vaccines, userInfo, dpes, dpeId).map((v) => ({
                                        gtinCode: v.gtinCode,
                                        name: `${v.name} ${v.packaging}`,
                                    }))}
                                    required
                                    optionsValue="gtinCode"
                                    optionsLabel="name"
                                    error={!!error}
                                    helperText={error?.message}
                                    label="Vaccin"
                                    value={value}
                                    disabled={!dpeId}
                                />
                            )}
                        />

                        <TextField
                            {...register("quantity")}
                            label={
                                selectedVaccine?.deliverer === DelivererType.SERVIPHAR
                                    ? "Quantité requise"
                                    : "Quantité livrée"
                            }
                            required
                            fullWidth
                            error={!!errors.quantity || !!quantityError}
                            helperText={(errors.quantity?.message as string) || quantityError}
                            slotProps={{
                                htmlInput: {
                                    maxLength: defaultIntegerLimit,
                                },
                            }}
                        />

                        <GenericTextField
                            value={selectedVaccine?.doseNumber * quantity || 0}
                            label="Doses totales"
                            disabled
                        />
                    </Stack>
                </Stack>
            </StyledCardContent>
            <button style={{ display: "none" }} onClick={handleSubmit(onValid)} ref={orderInformationRef} />
        </Card>
    );
}
