// === NPM
import React from "react";
import { useOutletContext } from "react-router-dom";
import { Card, Stack, Typography } from "@mui/material";
import { GridValueFormatterParams } from "@mui/x-data-grid-pro";
import { DateTime } from "luxon";
// === LOCAL
import { ReactComponent as Calendar } from "@/assets/icons/shared/calendar.svg";
import { ReactComponent as Location } from "@/assets/icons/shared/location.svg";
import { ReactComponent as Sum } from "@/assets/icons/vaccination/sum.svg";
import { ReactComponent as Vaccine } from "@/assets/icons/vaccination/vaccine.svg";
import GenericActionsDialog from "@/components/generics/dialogs/GenericActionsDialog";
import GenericDialog from "@/components/generics/dialogs/GenericDialog";
import GenericTable from "@/components/generics/table/GenericTable";
import { GenericIconText } from "@/components/generics/text/GenericIconText";
import { StyledCardContent } from "@/components/styled/StyledCardContent";
import { VaccinationOutletContext } from "@/interfaces/vaccination";
import { toLocaleDateFormat } from "@/resources/utils";
import { IOrderDetails } from "../interface";

interface ConfirmDialogProps {
    onClose: () => void;
    order: IOrderDetails;
}

export default function DetailsDialog({ onClose, order }: Readonly<ConfirmDialogProps>) {
    const { vaccines } = useOutletContext<VaccinationOutletContext>();

    const vaccine = vaccines.find((v) => v.gtinCode === order.vaccineGtinCode);

    const columns = [
        {
            field: "batchNumber",
            headerName: "N° lot de vaccin",
            flex: 1,
            sortable: false,
        },
        {
            field: "deliveryNumber",
            headerName: "N° bon de livraison",
            flex: 1,
            sortable: false,
        },
        {
            field: "expeditionDate",
            headerName: "Date d'expédition",
            flex: 1,
            valueFormatter: (params: GridValueFormatterParams<string>) =>
                params.value ? DateTime.fromISO(params.value).toLocaleString() : "",
            sortable: false,
        },
        {
            field: "deliveredQuantity",
            headerName: "Quantité livrée",
            flex: 1,
            sortable: false,
        },
        {
            field: "expirationDate",
            headerName: "Date de péremption",
            flex: 1,
            valueFormatter: (params: GridValueFormatterParams<string>) =>
                params.value ? DateTime.fromISO(params.value).toLocaleString() : "",
            sortable: false,
        },
    ];

    const renderContent = () => (
        <Card>
            <StyledCardContent>
                <Stack
                    spacing={3}
                    sx={{
                        p: 2,
                    }}
                >
                    <Stack spacing={1}>
                        <Typography variant="bold">Commande effectuée</Typography>
                        <GenericIconText
                            icon={<Vaccine />}
                            text={`${vaccine?.name} - ${vaccine?.packaging}`}
                            iconTooltip="Vaccin"
                        />
                        <GenericIconText
                            icon={<Sum />}
                            text={`${order.quantity} unités de ${vaccine?.doseNumber} doses chacune`}
                            iconTooltip="Quantité"
                        />
                        <GenericIconText
                            icon={<Location />}
                            text={`${order.deliveryName} ${order.address} ${order.complementaryAddress ?? ""}, ${order.postalCode} ${order.city}`}
                            iconTooltip="Adresse"
                        />
                        <GenericIconText
                            icon={<Calendar />}
                            text={toLocaleDateFormat(order.deliveryDate)}
                            iconTooltip="Date de livraison souhaité"
                        />
                    </Stack>
                    <Stack spacing={1}>
                        <Typography variant="bold">Informations du distributeur</Typography>
                        <GenericTable
                            rows={order.deliveries}
                            columns={columns}
                            autoHeight
                            hideFooter
                            noRowsMessage="Pas de livraison pour cette commande"
                            getRowId={(row) => row.uuid}
                        />
                    </Stack>
                </Stack>
            </StyledCardContent>
        </Card>
    );

    return (
        <GenericDialog
            maxWidth="lg"
            title={`Commande de vaccin n°${order.id}`}
            onClose={onClose}
            renderContent={() => renderContent()}
            renderActions={() => <GenericActionsDialog onClose={onClose} closeLabel="Fermer" displaySubmit={false} />}
        />
    );
}
