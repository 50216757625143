// === NPM
import React from "react";
import { Outlet, useOutletContext } from "react-router-dom";
import { Box, Typography } from "@mui/material";
// === LOCAL
import PageContent from "@/components/generics/layout/PageContent";
import { useRouteMatch } from "@/hooks/useRouteMatch";
import { UserType } from "@/interfaces/user";
import { Preference, UserSituation } from "@/resources/PermissionConstant";
import PermissionsTabs from "@/resources/PermissionsTabs";
import { routerLinks } from "@/routers/RouterConstant";
import { useAuth } from "@/routers/useAuth";
import { VaccinationIahpContext } from "../interface";
import { FormInterventionProvider } from "./useFormIntervention";

export default function VaccinationSite() {
    const auth = useAuth();

    const routeMatch = useRouteMatch([
        routerLinks.iahp.vaccinationSite.view(),
        routerLinks.iahp.vaccinationSite.form(),
        routerLinks.iahp.vaccinationSite.editForm(),
        routerLinks.iahp.vaccinationSite.vaccinationIntervention.base(),
        routerLinks.iahp.vaccinationSite.vaccinationIntervention.form(),
        routerLinks.iahp.vaccinationSite.vaccinationIntervention.editForm(),
    ]);
    const currentTab = routeMatch?.pattern?.path;

    const { vaccines, diluents, species, subSpecies, tiers, sectors, billingStatuses, horsePower } =
        useOutletContext<VaccinationIahpContext>();

    //Permet de gérer les états de modification des interventions de vaccination et de chantier
    //on doit rester sur les tabs mais avoir des URLs différentes en cas de modif
    const handleFirstTab = () => {
        switch (currentTab) {
            case routerLinks.iahp.vaccinationSite.view():
                return routerLinks.iahp.vaccinationSite.view();
            case routerLinks.iahp.vaccinationSite.vaccinationIntervention.form():
                return routerLinks.iahp.vaccinationSite.vaccinationIntervention.form();
            case routerLinks.iahp.vaccinationSite.vaccinationIntervention.editForm():
                return routerLinks.iahp.vaccinationSite.vaccinationIntervention.editForm();
        }
    };

    return (
        <>
            <PermissionsTabs
                tabControl={currentTab}
                tabs={[
                    {
                        value: handleFirstTab(),
                        url: routerLinks.iahp.vaccinationSite.view(),
                        label: "Mes chantiers",
                    },
                    {
                        url: routerLinks.iahp.vaccinationSite.form(),
                        value:
                            currentTab === routerLinks.iahp.vaccinationSite.editForm()
                                ? routerLinks.iahp.vaccinationSite.editForm()
                                : routerLinks.iahp.vaccinationSite.form(),
                        label: "Saisir un chantier",
                        permissions: [UserSituation.REGISTERED_IN_PRACTICE],
                        preferences: [Preference.IAHP],
                    },
                    {
                        url: routerLinks.iahp.vaccinationSite.vaccinationIntervention.base(),
                        label: "Mes interventions de vaccination",
                    },
                ]}
            />
            <FormInterventionProvider>
                <PageContent>
                    {auth.userInfo.type !== UserType.VETERINARY ||
                    auth.userInfo.properties.dpes.some((dpe) => dpe.sanitaryDpe) ? (
                        <Outlet
                            context={{
                                species,
                                subSpecies,
                                horsePower,
                                sectors,
                                tiers,
                                vaccines,
                                diluents,
                                billingStatuses,
                            }}
                        />
                    ) : (
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                width: "100%",
                            }}
                        >
                            <Typography variant="h4">
                                Vous ne pouvez pas effectuer de saisie car vous ne possédez pas de DPE ayant un lien
                                vétérinaire sanitaire.
                            </Typography>
                        </Box>
                    )}
                </PageContent>
            </FormInterventionProvider>
        </>
    );
}
